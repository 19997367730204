import { useI18next } from "gatsby-plugin-react-i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const StepsCompleted = {
    Pcr: {
        FirstStep: 0,
        PatientInformation: 1,
        ScanBarcode: 2,
        Consent: 3,
        Purpose: 4,
        Questionnaire: 5,
        InstructionsIntro: 6,
        InstructionsStepOne: 7,
        InstructionsStepTwo: 8,
        All: 9,
    }
};

export const StepUrls = {
    Pcr: {
        0: '/register',
        1: '/register/scan',
        2: '/register/consent',
        3: '/register/symptoms-and-history',
        4: '/test',
        6: '/test/step2',
        7: '/test/step3',
        8: 'dashboard'
    }
}

export const useRegistration = ({ currentStep }) => {

    const { currentRegistration, ...rest } = useSelector(s => s.registrations);
    const { navigate } = useI18next();

    console.log('currentStep', currentStep);
    console.log('currentRegistration', currentRegistration);

    useEffect(() => {
        if(!currentRegistration) {
            navigate('/dashboard');
        }
        if(currentStep && currentRegistration) {
            if(currentRegistration.step < currentStep) {
                navigate(StepUrls.Pcr[currentRegistration.step]);
            }
        }
    }, [ currentStep, currentRegistration ])

    return currentRegistration || {};
};