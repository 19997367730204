import React, { useCallback } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Button } from '../../components/button.component';
import { Layout } from '../../components/layout.component';

import { completeRegistration } from '../../services/registrations.service';
import { StepsCompleted, useRegistration } from '../../hooks/use-registration.hook';

export default function Home() {
    const { t, navigate } = useI18next();
    // const { currentRegistration } = useSelector(s => s.registrations);
    const registration = useRegistration(StepsCompleted.Pcr.All);

    const onComplete = useCallback(() => {
        completeRegistration(registration.id).then(r => {
            navigate('/dashboard');
        });
    }, [ registration, navigate ]);

    return <Layout>
        <div className="take-test animateIn">
            <div className="section section--instruction-end-1">
            </div>
            <div className="section section--instruction-end-2">
                <h1 className="t-h2 t-grey9">{t('That\'s all!')}</h1>
                <p className="t-sans t-grey7 t-p1 mb-25 finish__instruction">検査結果は、通常24時間から72時間以内（クイックの場合は、24時間以内）に通知いたします。陰性結果の場合は、登録いただいているEメールアドレスにお送りいたします。また、検査の状況はマイページでもご確認いただけます。発生届対象者の検査結果が陽性だった場合、こちらからお電話で直接ご連絡いたします（発生届対象者外の方については、各都道府県のホームページを確認し陽性者登録を行ってください）。</p>
                <div className="buttons">
                    <Button onClick={onComplete}>{t('Go to dashboard')}</Button>
                </div>
            </div>
        </div>
    </Layout>;
}
